.contact {
    color: white;
    padding-top: 75px;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 70px;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform: translateY(-20px);
    opacity: 0;
}

.contact-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    z-index: 2;
}

.contact h1 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
}

.contact p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #dcdcdc;
}
