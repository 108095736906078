.aboutme {
    color: white;
    padding-top: 75px;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 70px;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform: translateY(-20px);
    opacity: 0;
}

@media (max-width: 768px) {
    .aboutme {
        padding-top: 50px;
        margin-bottom: 50px;
    }
}

.aboutme-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    z-index: 2;
}

@media (max-width: 768px) {
    .aboutme-content {
        padding: 15px;
        max-width: 90%;
    }
}

.aboutme h1 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .aboutme h1 {
        font-size: 28px;
        margin-bottom: 15px;
    }
}

.aboutme p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #dcdcdc;
}

@media (max-width: 768px) {
    .aboutme p {
        font-size: 1rem;
        margin-bottom: 20px;
        line-height: 1.4;
    }
}

.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.flex img {
    height: 40px; /* Default height for desktop */
    margin: 5px; /* Add margin to ensure spacing around images */
}

@media (max-width: 768px) {
    .flex img {
        height: 30px; /* Smaller height for mobile devices */
    }
}