.hero {
    color: white;
    padding-top: 150px;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 70px;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform: translateY(-20px);
    opacity: 0;
}

.hero-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    z-index: 2;
}

.hero h1 {
    font-size: 4rem;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #dcdcdc;
}

.hero-button {
    display: inline-block; /* Add this to make padding and width work */
    background: rgba(0, 0, 0, 0.5); /* Slightly transparent */
    backdrop-filter: blur(5px); /* Blurred background */
    color: white;
    padding: 10px 30px;
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 10px; /* Rounded corners */
    border: 1px solid rgba(255, 255, 255, 0.4); /* Subtle border */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); /* Subtle shadow */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    text-decoration: none; /* Remove underline from links */
}

.hero-button:hover {
    transform: translateY(-10px);
}
