.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    margin-top: 20px; /* Added margin to the top */
    width: 40%; /* Shorter width */
    margin-left: auto; /* Center the navbar */
    margin-right: auto; /* Center the navbar */
    background: rgba(0, 0, 0, 0.5); /* Slightly transparent */
    backdrop-filter: blur(20px); /* Blurred background */
    color: white;
    border-radius: 30px; /* Rounded corners */
    border: 1px solid rgba(255, 255, 255, 0.4); /* Subtle border */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); /* Subtle shadow */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    z-index: 1000; /* Ensure the navbar is always on top */
    position: fixed; /* Fixed navbar */
    top: 0; /* Ensure the navbar is always at the top */
    left: 0; /* Ensure the navbar is always at the left */
    right: 0; /* Ensure the navbar is always at the right */
  }

  @media (max-width: 768px) {
    .navbar {
      justify-content: center; /* Center logo on mobile */
      width: 100%; /* Full width on mobile */
      margin-left: 4px; /* Remove left margin */
      margin-right: 4px; /* Remove right margin */
    }
    .nav-items {
      display: none; /* Hide navbar items on mobile */
    }
  }
 
  .logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: white; /* Teal color for the logo */
  }
 
  .nav-items a {
    color: white;
    text-decoration: none;
    margin-left: 20px;
    transition: color 0.3s;
    font-weight: 600;
  }