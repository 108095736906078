.projects-container {
    text-align: center;
    margin-left: 25%;
    margin-right: 25%;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform: translateY(-20px);
    opacity: 0;
  }
  
  @media (max-width: 768px) {
    .projects-container {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Display 2 cards per row */
    gap: 20px;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .projects-grid {
      grid-template-columns: 1fr; /* Display 1 card per row on mobile */
    }
  }
  
  .project-card {
    color: white;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.5); /* Slightly transparent */
    backdrop-filter: blur(5px); /* Blurred background */
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.4); /* Subtle border */
    box-shadow: 0 4px 10px rgba(0,0,0,0.4);
    transition: transform 0.3s ease-in-out;
  }
  
  .project-card:hover {
    transform: translateY(-10px);
  }
  
  .project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .project-info {
    padding: 20px; /* Increased padding for better spacing */
    text-align: center;
    border-radius: 8px; /* Rounded corners */
  }
  
  #projects-title {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    #projects-title {
      font-size: 28px; /* Smaller font size on mobile */
    }
  }
  
  h3 {
    font-size: 24px; /* Larger font size */
    font-weight: bold; /* Bold font weight */
    color: #E0E0E0; /* Lighter color for better readability */
    margin-top: 0;
    margin-bottom: 10px; /* Space between title and description */
  }
  
  .project-info p {
    font-size: 16px; /* Larger font size for readability */
    line-height: 1.5; /* Increased line height for better text flow */
    color: #CCCCCC; /* Lighter grey color for easier reading */
  }